import * as React from 'react'
import WebCamSubscription from './WebCamSubscription'

const WebCamSubscriptionContainer = (props: {show: boolean}) => {
  if(props.show) {
    return(
      <WebCamSubscription />
    )
  } else {
    return null
  }
}

export default WebCamSubscriptionContainer
