import * as React from 'react'
import { useSubscription, gql } from '@apollo/client'
import WebCam from './WebCam'

export const WEBCAM_UPDATED_SUBSCRIPTION = gql`
  subscription {
    webcamUpdated
  }
`

interface GraphQLData {
  webcamUpdated: string
}

const WebCamSubscription = () => {
// eslint-disable-next-line
  const { loading, error, data } = useSubscription<GraphQLData>(WEBCAM_UPDATED_SUBSCRIPTION)

  return(
    <WebCam show={true} now={Date.now()}/>
  )
}

export default WebCamSubscription
